<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <ass_stat></ass_stat>
      </v-col>
      <v-col cols="12">
        <distinct_open_association></distinct_open_association>
      </v-col>
      <v-col cols="12">
        <all_association_data_grid></all_association_data_grid>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "open_cases",
  components: {
    ass_stat: () => import("@/components/dashboard/Stats/ass_stat"),
    all_association_data_grid: () =>
      import("@/components/dashboard/DataGrids/all_association_data_grid"),
    distinct_open_association: () =>
      import("@/components/dashboard/distinct_open_association")
  }
};
</script>

<style scoped></style>
